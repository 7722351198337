//登录
const loginUrl = `/gateway/blade-system/login`;
//新登录接口
const newLoginUrl = `/gateway/hc-auth/token`;
// 浙里办登录接口
const zlbLoginUrl = `/gateway/hc-auth/tokenIrsSSO`;
// 获取验证码
const getVerificationCodeUrl = `/gateway/blade-user/user/getVerificationCode`;
// 校验验证码
const checkVerificationCodeUrl = `/gateway/blade-user/user/checkVerificationCode`;
// 修改密码
const changeLoginPwdUrl = `/gateway/blade-user/user/changeLoginPwd`;

export {
  loginUrl,
  newLoginUrl,
  getVerificationCodeUrl,
  checkVerificationCodeUrl,
  changeLoginPwdUrl,
  zlbLoginUrl,
};
