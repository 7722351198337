<template>
  <div class="login-container" v-loading="loading"></div>
</template>

<script>
import { AESEcbEncrypt, AESEcbDecrypt } from "@/utils/common";
import { loginUrl } from "./api";
import { mapState } from "vuex";
export default {
  name: "JumpLogin",
  data() {
    return {
      loading: true,
      menu: [],
    };
  },
  computed: {
    ...mapState("app", ["menuLimit"]),
  },
  created() {
    this.toLogin();
  },
  methods: {
    async toLogin() {
      this.phone = AESEcbDecrypt(decodeURIComponent(this.$route.query.token));
      let params = {
        phone: this.phone,
      };
      let res = await this.$axios.post(`${loginUrl}`, params);
      if (res.code === 200 && res.success) {
        let userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.$store.commit("app/setUserInfo", userInfo);
        await this.getMenu();
        this.findUrl(this.menu);
        this.$router.replace({
          name: this.url,
        });
      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    async getMenu() {
      let params = {
        roleId: this.$store.state.app.userInfo.roleId,
        userId: this.$store.state.app.userInfo.id,
      };
      let url = `/gateway/blade-system/menu/routes`;
      if (this.menuLimit.name && this.menuLimit.type) {
        url = `/gateway/blade-system/menu/routes?domain=${this.menuLimit.type}`;
      }
      let res = await this.$axios.post(url, params);
      if (res.code === 200) {
        this.menu = res.data;
      }
    },
    findUrl(oList) {
      if (oList[0] && oList[0].url) {
        this.url = oList[0].url;
        return "";
      } else {
        this.findUrl(oList[0].children);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  height: 100%;
}
</style>
